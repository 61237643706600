.custom-typeahead .dropdown-item {
  &.active, &:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
}

.label-filter-date {
  text-align: right;
}
.label-filter-date > label {
  padding-top: .3rem;
  margin-bottom: 0;
  font-size: .875rem;
}

.date-range-inputs-container {
  display:         flex;
  flex-flow:       row wrap;
  justify-content: flex-start;
  align-items:  stretch;

  .date-range-inputs {
    width:     32%;
    min-width: 370px;
  }
}

#cpx_login_form {
  max-width: 400px;
}
