$blue:                              #635ebe;
$dark-green:                        #286f77;
$indigo:                            #6610f2;
$purple:                            #6f42c1;
$pink:                              #f53282;
$red:                               #ff5c75;
$orange:                            #fd7e14;
$yellow:                            #FFCE67;
$green:                             #2fbfa0;
$teal:                              #20c997;
$cyan:                              #399AF2;

$white:                             #fff;
$gray-50:                           #f0f6ff;
$gray-100:                          #dde9f5;
$gray-200:                          #cbdaea;
$gray-300:                          #b6cade;
$gray-400:                          #a4bad1;
$gray-500:                          #93acc6;
$gray-600:                          #839bb3;
$gray-700:                          #718599;
$gray-800:                          #617182;
$gray-900:                          #4d5a68;
$black:                             #000;

$primary:                           $dark-green;
$secondary:                         #788db4;
$success:                           $green;
$info:                              $cyan;
$warning:                           $yellow;
$danger:                            $red;
$light:                             $gray-50;
$dark:                              #15182b;

$color-secondary-state:             $secondary;
$color-success-state:               $success;
$color-danger-state:                $danger;
$color-warning-state:               $warning;
$color-info-state:                  $info;


$body-bg:                           $gray-50;
$body-color:                        $gray-800;

$header_background_color:           $white;
$header_emergency_background_color: red;
$header_emergency_color:            $white;
$header_menu_patient_color:         $pink;

$table-border-color:                $gray-50;
