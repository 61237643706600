/*=========================================================================
Base
========================================================================== */
.modal {
  button.close {
    color: $body-color;
    text-shadow: none;

    &:active,
    &:focus {
      outline: 0;
    }
  }

  &[data-modal="scroll"] {
    .modal-body {
      max-height: 450px;
      overflow-y: auto;
    }
  }
  .modal-content{
    border: 0;
  }
}

.modal-xl{
  .modal-dialog{
    max-width: 1200px;
  }
}

.modal-90w {
  max-width: 1300px;
  width: 90%;
}
