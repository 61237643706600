.header-wrapper {
  width:      100%;
  height:     $header-height;
  background: $header_background_color;

  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, .08);

  > .container, > .container-lg {
    padding: 0 15px;
  }

  & .dropdown.nav-item {
    padding: 0.5rem 0.5rem;
  }

  & .nav-link,
  & .nav-item {
    font-size: .875rem;
  }

  & .nav-item i,
  & a.nav-link i,
  & .navbar-text i {
    margin:         0;
    font-size:      24px;
    width:          25px;
    display:        inline-block;
    vertical-align: sub;
  }

  & .navbar-nav > .nav-link,
  & .navbar-nav > span > .nav-link,
  & .navbar-text,
  & .navbar-nav > .nav-link-container {
    padding-top: 1rem;
  }

  & .navbar-nav > .nav-link-container > .nav-link {
    padding-top: 0;
  }

  & .dropdown.patient-menu {
    & .dropdown-menu {
      box-shadow: 0 10px 20px rgba(0, 0, 0, .08);
      border:     none;

      & .dropdown-divider {
        border-top: 1px solid $gray-50;
      }
    }

    & .dropdown-item {
      padding: 10px 35px;
    }

    & .dropdown-item,
    & .nav-item,
    & .nav-link {
      color: $header_menu_patient_color;

      &:hover,
      &:active {
        color: $primary;

        & i {
          color: $primary;
        }
      }
    }
  }

  &.emergency {
    background-color: $header_emergency_background_color;
    color:            $header_emergency_color;

    & .navbar-text,
    & .navbar-text i,
    & .nav-link,
    & .nav-link i,
    & .nav-item,
    & .nav-item i,
    & .nav-link:hover,
    & .nav-link:focus,
    & .nav-link:hover i,
    & .nav-link:focus i,
    & .nav-item:hover,
    & .nav-item:focus,
    & .nav-item:hover i,
    & .nav-item:focus i,
    & .dropdown.patient-menu .nav-item,
    & .dropdown.patient-menu .nav-link,
    & .dropdown.patient-menu .nav-item i,
    & .dropdown.patient-menu .nav-link i {
      color: $header_emergency_color;
    }

    & .navbar-text.emergency-notif > * {
      font-weight: 600;
    }
  }
  & .right {
    margin-left: auto;
  }
  & .right ~ .right {
    margin-left: 0;
  }
}

@media(max-width: map-get($grid-breakpoints, xl)) {
  .header-wrapper {
    & .navbar-nav > .nav-link > span:not(.badge),
    & .navbar-nav > span > .nav-link > span:not(.badge),
    & .navbar-text > span,
    & .navbar-nav > .nav-link-container > a > span:not(.badge)
    {
      display: none;
    }
  }
}
