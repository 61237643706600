.table th, .table td {
  vertical-align: middle;
}

.dmp-status-no-ins {
  width: 33px;
  margin: 3.5px 0px 3.5px 0px;
  margin-right: 4px;
}

.pointer {
  cursor: pointer;
}

.bg-danger > button.close,
.bg-warning > button.close {
  color: white !important;
}

.loginLogo {
  width: 200px;
  margin: 0 auto;
  display: block;
}

@keyframes fadeIn {
  from { opacity: 0; }
}
.detection {
  margin-left: $spacer * 1.5;
  font-size: 24pt;
  animation: fadeIn 1s infinite alternate;
}

.batch-result-container {
  padding: 0 0 $spacer 0;
  margin-bottom: $spacer * 1.5;
  border-bottom: 1px solid $gray-300;

  &:last-of-type, &:only-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}