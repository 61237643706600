.upload-preview-container {
  display:       flex;
  flex-flow:     row;
  padding-left:  15px;
  padding-right: 15px;

  & div.drop-zone-container {
    flex-grow: 1;
    width:     100%;
  }
}

.documentPreview {
  position: relative;

  & .documentPage {
    position:   relative;
    padding:    6px;
    overflow:   hidden;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
  }
}

.preview-container-lg {
  & .documentPage {
    overflow:  scroll;
    width:     100% !important;
    height:    auto !important;
    padding:   30px;
    font-size: 80% !important;

    & * {
      font-size: 80% !important;
    }

    &.rtf {
      & * {
        font-size: calc(attr(font-size pt, 12) * 80%) !important;
      }

      & div {
        min-height: 8pt !important;
      }
    }

    &.rtf * {
      font-size: calc(attr(font-size pt, 12) * 80%) !important;
    }
  }
}

.preview-container-sm {
  margin-right: 15px;
  //width:        calc(50% * (21 / 29.7)) !important;

  & .documentPage {
    font-size: 30% !important;

    & * {
      font-size: 30% !important;
    }

    &.rtf {
      & * {
        font-size:  calc(attr(font-size pt, 12) * 30%) !important;
        min-height: calc(attr(min-height pt, 0) * 30%) !important;
      }

      & div {
        min-height: 3pt !important;
      }
    }
  }

  & ~ div {
    flex-grow: 1;
    width:     100%;
  }
}

.action-document {
  position: relative;

  & .action-document-button {
    display:  none;
    position: absolute;
    top:      10px;
    //width:    100%;

    & button {
      margin-bottom: 3px;
      display:       block;

      &.show-text-on-hover {
        & span {
          display: none;
        }

        &:hover span {
          display: inline;
        }
      }

      & i {
        color: #fff;
      }
    }

    &.center {
      left:      50%;
      transform: translateX(-50%);

    }

    &.right {
      right:     10px;
      direction: rtl;
    }


  }

  &:hover .action-document-button {
    display: block;
  }
}

@media(min-width: map-get($grid-breakpoints, md)) {
  .preview-container-sm {
    & .documentPage {
      width:  calc(150px * (21 / 29.7)) !important;
      height: 150px !important;
    }
  }
  .preview-container-lg {
    & .documentPage {
      width:    calc(850px * (21 / 29.7)) !important;
      height:   850px !important;
      overflow: hidden;
    }
  }
}

@media(min-width: map-get($grid-breakpoints, lg)) {
  .preview-container-sm {
    & .documentPage {
      width:  calc(250px * (21 / 29.7)) !important;
      height: 250px !important;
    }
  }
}


@media(max-width: 1024px) {
  .preview-container-lg + .upload-form-container {
    display: none;
  }
}

.preview-container-sm img.documentPage, .preview-container-lg img.documentPage {
  height: auto !important;
}

.virtual-printer-file-list {
  display:         flex;
  flex-flow:       row wrap;
  justify-content: flex-start;

  & .thumbnail {
    margin:    5px 0 5px calc(4% / 3);
    width:     32%;
    min-width: 280px;
    //max-width: 300px;

    //&:not(:first-child) {
    //  margin-left: 15px;
    //}
  }
}
