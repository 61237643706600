.breadcrumb-wrapper {
	display: inline-block;

	.breadcrumb {
		background-color: transparent;
		border-radius: 0;
		margin: 0;
		padding: 0 1rem;

		.breadcrumb-item {
			font-size: $font-size-sm;
			font-weight: $font-weight-normal;
			color: $gray-600;

			> a {
				font-size: $font-size-sm;
				color: $gray-600;
				font-weight: $font-weight-normal;
				vertical-align: middle;
				&:active,
				&:hover {
					color: $primary;
				}
			}

			> a > i {
				font-size: rem(15);
				color: $gray-600;
				&:active,
				&:hover {
					color: $primary;
				}
			}
		}
	}

	.breadcrumb-item + .breadcrumb-item::before {
		display: inline-block;
		padding-right: 4px;
		padding-left: 4px;
		color: $gray-600;
		content: "-";
		font-size: $font-size-sm;
	}
}

@media (max-width: 768px) {
	.breadcrumb-wrapper {
		display: none;
	}
}
