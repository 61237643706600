/*-----------------------------------------------------------------------
APPLICATION LEVEL LAYOUT
-----------------------------------------------------------------------*/
body {
  &.layout-horizontal {
    & .content-wrapper {
      margin-top:     $content-wrapper-margin-top;
      height:         100%;
      padding-bottom: 15px;
      box-sizing:     border-box;
      display:        flex;
      flex-flow:      column;

      &.container-fluid {
        padding-left:   15px;
        padding-right:  15px;
        padding-bottom: 15px;
      }
    }
  }
}

.centered-container {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.documentContent {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100%;
  -ms-flex-negative: 0;
}

.documentContentHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  -ms-flex-negative: 0;
}

.documentContentBody {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  height: 100%;
  -ms-flex-negative: 0;
}

.documentContentData {
  height: 100%;
  -ms-flex-negative: 0;
}

.top, .centered {
  display: flex;
  flex-flow: column;
}

.h-centered > * {
  margin-right: auto;
  margin-left: auto;
}

.v-centered > * {
  margin-top: auto;
  margin-bottom: auto;
}

