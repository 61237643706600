$input-btn-focus-width:  0;

$input-btn-padding-y:    .40rem;
$input-btn-padding-x:    1.25rem;
$input-btn-padding-y-sm: .35rem;
$input-btn-padding-x-sm: .75rem;
$input-btn-padding-y-lg: 1.25rem;
$input-btn-padding-x-lg: 2.5rem;

$input-btn-font-size-sm: .75rem;
$input-btn-font-size:    $font-size-sm;
$input-btn-font-size-sm: .75rem;
$input-btn-font-size-lg: $font-size-base;
$input-btn-line-height-lg: .2;
