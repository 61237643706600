.access-container.accordion {
  > .card {
    margin-bottom: $accordion-card-margin-bottom;
    border-radius: 0 !important;

    > .card-header {
      height: $accordion-header-height;

      h2 {
        font-size:   1.1rem;
        font-weight: 400;
      }

      .btn-link {
        color:          #556579;
        display:        table-cell;
        vertical-align: middle;
        font-size:      1.1rem;
        font-weight:    400;
        padding:        6px 17px 6px 0;
        position:       absolute;
        left:           0;
        top:            0;
        right:          0;
        bottom:         0;
        height:         50px;
        width:          100%;
        text-align:     left;
        padding-left:   20px;

        &:not(.loading):after {
          position:                 absolute;
          content:                  '';
          width:                    .5em;
          height:                   .5em;
          border-width:             1px 0 0 1px;
          border-style:             solid;
          border-color:             initial;
          right:                    1.3em;
          -webkit-transform-origin: top;
          -ms-transform-origin:     top;
          -o-transform-origin:      top;
          transform-origin:         top;
          top:                      44%;
          -webkit-transition:       all .3s ease-out;
          -o-transition:            all .3s ease-out;
          transition:               all .3s ease-out;
        }

        &.collapsed {
          &:after {
            -webkit-transform: rotate(-135deg) translate(0, -50%);
            -ms-transform:     rotate(-135deg) translate(0, -50%);
            -o-transform:      rotate(-135deg) translate(0, -50%);
            transform:         rotate(-135deg) translate(0, -50%);
          }

        }

        &:after {
          -webkit-transform: rotate(-315deg) translate(0, -50%);
          -ms-transform:     rotate(-315deg) translate(0, -50%);
          -o-transform:      rotate(-315deg) translate(0, -50%);
          transform:         rotate(-315deg) translate(0, -50%);
        }
      }
    }

    & .card-body-maximized {
      height: 300px;

      &.show {
        display:    flex;
        flex-flow:  column;
        min-height: 280px;
        height:     calc(
                      100vh/* viewport height */
                      - #{$content-wrapper-margin-top}/* content wrapper padding top */
                      - 15px/* content wrapper padding bottom */
                      - (55px * 4)
                    );

        &.large {
          min-height: 400px;
        }
      }

      & .ReactTable {
        flex:       1 1 auto;
        min-height: 170px;
      }
    }
  }

  &.virtual-printer {
    & .card {
      & .card-body-maximized {
        &.show {
          height: auto;
        }
      }
    }
  }
}
