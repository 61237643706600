#insi_result {
  & .card-title {
    font-weight: bold;
    text-align: center;
    border-top: 1px $gray-100 solid;
    margin-top: $spacer * 1;
    padding-top: $spacer * 0.5;
  }

  & #insi_result_historic {
    & .insi_result_historic_entry:not(:first-of-type) {
      margin-top: $spacer * 1;
      padding-top: $spacer * 0.5;
    }
  }
}

.buttons-container {
  & button {
    width: 350px;
  }
}