html, body, #root, #app {
  height: 100%;
  display: flex;
  flex-flow: column;
  letter-spacing: .02rem;
}

*, :active, :focus {
  outline: 0;
}

label, p, ol li, ul li, table {
  font-size: $font-size-sm !important;
}
