.btn {
  transition: all .2s ease;
}

.btn-xs {
  padding: 0.3rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
}

@each $color, $value in $theme-colors {
  .btn-#{$color}:not(:disabled):not(.disabled):hover {
    box-shadow: 0 5px 10px rgba(theme-color($color), .4);
    background-color: darken(theme-color($color), 3%);
    border-color: darken(theme-color($color), 3%);
  }
  .btn-outline-#{$color}:not(:disabled):not(.disabled) {
    border-color:     lighten(theme-color($color), 30%);

    &:active,
    &.active, {
      background-color: theme-color($color) !important;
      border-color:     theme-color($color);
      box-shadow:       0 2px 2px 0 rgba(theme-color($color), 0.14),
                        0 3px 1px -2px rgba(theme-color($color), 0.2),
                        0 1px 5px 0 rgba(theme-color($color), 0.12);
    }

    &:hover {
      background-color: darken(theme-color($color), 3%) !important;
      border-color:     darken(theme-color($color), 3%);
      box-shadow:       0 5px 10px rgba(theme-color($color), .4);
    }
  }
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  border-left-width: 0;
}


.btn-link {
  color: $body-color;
}

.btn i, a:link.btn i {
  vertical-align: middle !important;
  margin: -1px 4px 0;
}
.btn-icon {
  border: none;
  color: $secondary;

  & i {
    font-size: 1.25rem;
  }
}

.btn-icon-only {
  &.btn {
    padding: 0.4rem;
    margin-right: map-get($spacers, 1);
    margin-top: map-get($spacers, 1);
  }
  & i {
    font-size: 1.5rem;
  }
}

@media(max-width: map-get($grid-breakpoints, lg)) {
  .hide-text-md > span {
    display: none;
  }
}
