.page-content {
	display: flex;
	flex-flow: column;
  box-sizing: border-box;

  &.full-height {
    flex-grow: 1;
    height: 100%;
  }
}
@media (max-width: 768px) {
	.page-header.container,
	.page-content.container{
		    max-width: 100%;
	}
}
@media (max-width: 576px) {
	.page-content {
		padding: 0!important;
	}
}

@include media-breakpoint-down(md) {
	.content.container{
		max-width: 100%;
		transition: all 0.3s ease;
	}
	.content-wrapper {
		.content {
			margin-top: 65px;
		}
	}
}
