$enable-responsive-font-sizes:              true;
//$enable-prefers-reduced-motion-media-query: false;

/*
  DO NOT CHANGE THE IMPORT ORDER
 */
@import "base/layout";
@import "base/color";
@import "base/font";
@import "base/forms-input-btn-base";
@import "base/forms-input";
@import "base/button";
@import "base/alert";
@import "base/nav";
@import "base/heading";
@import "base/link";
@import "base/dropdown-menu";
@import "base/modal";

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "base/general";
/*
  DO NOT CHANGE THE IMPORT ORDER
 */

@import "layouts/main";

@import "extra/button";
@import "extra/nav-tab-container";
@import "extra/forms-switches";
@import "extra/alert";
@import "extra/accordions";
@import "extra/dropdown-menu";
@import "extra/forms";
@import "extra/card";
@import "extra/modal";
@import "extra/misc";
