.alert-warning, .btn-warning {
  color: theme-color-level('warning', 7);
}
.alert-info {
  border-color: $gray-100;
  color: $body-color;
  background-color: $gray-50;
}

.alert.danger-outlined {
  color: $body-color;
  background-color: $card-bg;
  .alert-heading {
    color: $color-danger-state;
  }
}