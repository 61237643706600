.tgl {
	display: none;
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}

	+ .tgl-btn {
		outline: 0;
		display: block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .tgl-btn:after {
		left: 50%;
	}
}

// themes
@each $color, $value in $theme-colors {
  .tgl-#{$color} {
    + .tgl-btn {
      background:    #d2deec;
      border-radius: 2em;
      padding:       2px;
      transition:    all .4s ease;

      &:after {
        border-radius: 50%;
        background:    #fff;
        transition:    all .2s ease;
      }
    }

    &:checked + .tgl-btn {
      background: theme-color-level($color, -2);
    }

    &[disabled] {
      + .tgl-btn {
        background:    #f0f6ff;
        border-radius: 2em;
        padding:       2px;
        transition:    all .4s ease;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.tgl-label {
  padding-left: .5em;
  cursor: pointer;
}
